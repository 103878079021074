import React, { useState } from 'react'

import { ChakraProvider, Spinner, extendTheme } from '@chakra-ui/react'
import { useEthers, DAppProvider, Mainnet } from '@usedapp/core'
import styled from 'styled-components'

import './App.css'
import Discord from './assets/discord.png'
import Twitter from './assets/twitter.png'
import { ContractDropDownList } from './components/ContractDropDown'
import CryoContainer from './components/CryoContainer'
import Header from './components/Header'
import PageLayout from './components/PageLayout'
import LoadingProvider, { useLoading } from './context/LoadingContext'
import GlobalStyles from './styles/GlobalStyles'
import color from './styles/mixins/color'
import ThemeProvider from './styles/ThemeProvider'
import { connect, connected, getAddress, getContract, getNFTData } from './web3'

const { gtag, install } = require('ga-gtag')

const BasicContainer = styled.div`
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding-top: 25px;
`

const BasicHeader = styled.h2`
  margin-bottom: 50px;
`

const TileContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
  align-items: flex-start;
  flex: 1;
`

const BasicButton = styled.button`
  width: 300px;
  margin: 20px;
  padding: 15px;
  font-size: 125%;
`
const BasicInput = styled.input`
  width: 100%;
  border: none;
  color: #fff;
  font-size: 250%;
  background: rgba(255, 255, 255, 0.1);
  padding: 10px;
  text-align: right;
  font-family: 'Changa', sans-serif;
  text-transform: uppercase;
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`

const Tile = styled.div`
  text-align: center;
  display: flex;
  flex-grow: 1;
  width: 50%;
  box-sizing: border-box;
  & + & {
    margin-left: 2%;
  }
  margin: 0;
  padding: 0;
  text-align: center;
  align-items: center;
  justify-content: center;
`

const ImageTile = styled(Tile)`
  height: 250px;
`

const LargeTile = styled(Tile)`
  flex: 1;
`

const Descriptor = styled.p`
  display: block;
  margin-bottom: 20px;
  margin-top: 20px;
`

const Footer = styled.div`
  margin-top: 10%;
  font-size: 120%;
  line-height: 1.25em;
  padding-bottom: 200px;
`

const Social = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 25px;
  flex-direction: row;
  a {
    margin: 0 15px;
  }
  img {
    width: 55px;
  }
`

const InputTyle = styled(Tile)`
  flex: 1;
`

const ImageOverlayContainer = styled.div`
  display: block;
  position: relative;
`

const customTheme = extendTheme({
  borders: {
    '3px': '3px solid #0AABD2',
  },
  styles: {
    global: {
      body: {
        fontFamily: null,
        lineHeight: null,
        color: '#fff',
      },
    },
  },
})

function App() {
  const { account, chainId } = useEthers()
  const [isConnected, setConnected] = useState('No')
  const [wallet, setAddress] = useState('')
  const [checkContractAddress, setCheckContractAddress] = useState('')
  const [nftRequest, setNftRequest] = useState('')
  const [tokenId, setTokenId] = useState('')
  const [previewUrl, setPreviewUrl] = useState('')
  const [statusMessage, setStatusMessage] = useState('')
  const [isButtonEnabled, setButtonEnabled] = useState(false)
  const [svgSource, setSVGSource] = useState('')
  const [isLoadingThumb, setIsLoadingThumb] = useState(false)
  const [readyForThumb, setIsReadyForThumb] = useState(false)

  const loader = useLoading()

  const connectToWeb3 = async () => {
    console.log('connecting...')
    await connect()
    setAddress(await getAddress())
    setConnected(connected() ? 'Yes' : 'No')
    gtag('event', 'connect')
  }

  const checkContract = async () => {
    const c = await getContract('PxlbotCryo')
    const isApproved = await c.approved_contracts(checkContractAddress)
  }

  const clearOptions = async () => {
    setTokenId('')
    setPreviewUrl('')
    setSVGSource('')
    setIsReadyForThumb(false)
  }

  const changePicker = async (newvalue: string) => {
    clearOptions()
    setNftRequest(newvalue)
  }

  const checkPreviewUrl = async () => {
    setIsLoadingThumb(true)
    setPreviewUrl('')
    setSVGSource('')
    if (nftRequest && tokenId) {
      setIsReadyForThumb(true)
    }
    try {
      const data: any = await getNFTData(nftRequest, tokenId)
      console.log('nft data', data)
      if (data && data.media && data.media.length > 0) {
        const media = data.media[0].thumbnail
        if (media) {
          setPreviewUrl(String(data.media[0].thumbnail))
          setButtonEnabled(true)
        } else if (data.media[0].gateway) {
          setPreviewUrl(String(data.media[0].gateway))
        } else if (data.media[0].raw) {
          console.log('no thumb, getting raw', data.media)
          if (data.media[0].raw.indexOf('svg+xml') < 0) {
            setPreviewUrl(String(data.media[0].raw))
          } else {
            const img_data = String(data.media[0].raw).split(
              'data:image/svg+xml',
            )[1]
            let src: any
            if (img_data.indexOf('utf8') > -1) {
              //cryptopunk?
              src = img_data.split(';utf8,')[1]
            } else if (img_data.indexOf('base64') > -1) {
              src = atob(img_data.split(';base64,')[1])
            }
            if (src) {
              setSVGSource(src)
              onImgLoaded()
            }
          }
          setButtonEnabled(true)
        }
      } else if (data.rawMetadata.image_url) {
        setPreviewUrl(data.rawMetadata.image_url)
        setButtonEnabled(true)
      }
    } catch (err) {
      setPreviewUrl('')
    }
  }

  const requestCryo = async () => {
    gtag('event', 'request')
    setStatusMessage(' ')
    const c = await getContract('PxlbotCryo')
    // loader.startLoading('REQUESTING CRYO...', true)
    console.log('submitting', nftRequest, tokenId, 'to', c.address)
    gtag('event', 'request_nft', {
      address: nftRequest,
      token_id: tokenId,
    })
    try {
      await c.request(nftRequest, Number(tokenId))
      setStatusMessage(
        'Request submitted. Please be patient, your NFT will be airdropped to you in the next 10–15 minutes.',
      )
    } catch (err: any) {
      console.log('err', err.toString())
      let msg = 'Oops.'
      if (String(err).indexOf('not approved') > -1) {
        msg +=
          " This contract hasn't been added to our list. Once we've received at least 15 submissions with this contract, we'll add it."
      }
      if (String(err).indexOf('Only token owner') > -1) {
        msg += ' You need to own this NFT.'
      }
      setStatusMessage(msg)
    }
  }

  const checkConnected = async () => {
    setConnected(connected() ? 'Yes' : 'No')
  }

  const validateNumInput = (str: any): number => {
    const val = Number(str)
    if (val > 0) {
      return val
    }
    return 1
  }

  const handleKeyUp = async (e: any) => {
    if (e.key === 'Enter') {
      checkPreviewUrl()
    }
  }

  const onImgLoaded = () => {
    setIsLoadingThumb(false)
  }

  return (
    <ThemeProvider>
      <ChakraProvider resetCSS={false} theme={customTheme}>
        <Header />
        <GlobalStyles />
        <DAppProvider
          config={{
            // autoConnect: true,
            readOnlyChainId: Mainnet.chainId,
            readOnlyUrls: {
              [Mainnet.chainId]:
                'https://mainnet.infura.io/v3/b40a79132b1c4d7ba28caf01817d9b1e',
            },
            networks: [Mainnet],
          }}>
          <PageLayout>
            <>
              {isConnected === 'No' ? (
                <BasicContainer>
                  <h2>
                    CONNECT YOUR METAMASK TO WRAP YOUR PIXELATED NFT IN A NEW,
                    AIRDROPPED PXLBOT
                  </h2>
                  <BasicButton onClick={connectToWeb3}>
                    Connect Metamask
                  </BasicButton>
                </BasicContainer>
              ) : (
                <>
                  <BasicContainer>
                    <BasicHeader>
                      Specify the NFT you own and we'll mint you a free, custom
                      cryo pxlbot.
                    </BasicHeader>
                    {/* <BasicInput
                    name="Contract"
                    value={checkContractAddress}
                    onChange={(e) => {
                      setCheckContractAddress(e.target.value)
                    }}
                  />
                  <BasicButton onClick={checkContract}>
                    Check contract
                  </BasicButton> */}
                    <TileContainer>
                      <InputTyle>
                        <Descriptor>NFT Collection:</Descriptor>
                        <ContractDropDownList onChange={changePicker} />
                        <Descriptor>Token ID:</Descriptor>
                        <BasicInput
                          name="tokenId"
                          value={tokenId}
                          onBlur={checkPreviewUrl}
                          onKeyUp={handleKeyUp}
                          type="text"
                          pattern="[0-9]*"
                          onChange={(e) => {
                            setTokenId(e.target.value)
                          }}
                        />
                        {statusMessage ? (
                          <Descriptor>{statusMessage}</Descriptor>
                        ) : (
                          <></>
                        )}
                        <BasicButton
                          disabled={!isButtonEnabled}
                          onClick={requestCryo}>
                          Request "Cryo" NFT
                        </BasicButton>
                      </InputTyle>
                      <CryoContainer
                        url={previewUrl}
                        svg={svgSource}
                        ready={readyForThumb}
                      />
                    </TileContainer>
                  </BasicContainer>
                </>
              )}
            </>
            <Footer>
              <p>
                Your NFT not supported? @ us on Twitter or let us know on
                Discord.
              </p>
              <Social>
                <a
                  href="https://twitter.com/pxlbots"
                  target="_blank"
                  rel="noreferrer">
                  <img src={Twitter} />
                </a>
                <a
                  href="http://dsc.gg/pxlbots"
                  target="_blank"
                  rel="noreferrer">
                  <img src={Discord} />
                </a>
              </Social>
              PXLBOTS IS A GAME PLATFORM AND COLLECTIBLE NFT, AN INTERGALACTIC
              BATTLE FOR SURVIVAL AND RESOURCES WHERE YOUR ACTIONS—AND
              FACTIONS—SHAPE THE PXLVERSE!
              <br />
              <br /> LAUNCHING SEPT 2022 — JOIN THE UPRISING AT PXLBOTS.COM
            </Footer>
          </PageLayout>
        </DAppProvider>
      </ChakraProvider>
    </ThemeProvider>
  )
}

export default App
