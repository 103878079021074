import styled from 'styled-components'

import Logo from '../assets/logo-new.svg'
import spacing from '../styles/mixins/spacing'
import ResponsiveImage from './ResponsiveImage'

const HeaderContainer = styled.header`
  padding: ${spacing(15)};
  align-items: center;
  justify-content: center;
`

const HeaderTitle = styled.header`
  font-size: 2.95em;
  text-align: center;
  text-transform: uppercase;
`

export default function Header() {
  return (
    <HeaderContainer>
      <ResponsiveImage src={Logo} />
      <HeaderTitle>Cryo</HeaderTitle>
    </HeaderContainer>
  )
}
