import styled from 'styled-components'

import color from '../styles/mixins/color'

const SVGContainer = styled.div`
  svg {
    width: 100% !important;
    height: auto !important;
  }
`

export default function SVG(props: { svg: any; disabled?: boolean }) {
  return (
    <SVGContainer
      style={{ display: props.disabled ? 'none' : 'block' }}
      dangerouslySetInnerHTML={{ __html: props.svg }}
    />
  )
}
