import { Network, Alchemy } from 'alchemy-sdk'
import { Contract, ethers } from 'ethers'

import manifests from './manifests'
import { Ethereumish } from './metamask'

declare global {
  interface Window {
    ethereum: any
  }
}
const metamask = window.ethereum as Ethereumish

let signer: ethers.providers.JsonRpcSigner
let cache: Record<string, Contract> = {}
let isConnected = false
let network = 0

export const getSigner = async (refresh?: boolean) => {
  if (!signer || refresh) {
    if (refresh) {
      cache = {}
    }
    //@ts-ignore
    await metamask.request({ method: 'eth_requestAccounts' })
    const provider = new ethers.providers.Web3Provider(metamask)
    signer = provider.getSigner()
  }
  return signer
}

export const connect = async (refresh?: boolean) => {
  if (!network || refresh) {
    await getChain()
  }
  await getSigner(refresh)
  isConnected = true
  console.log('isConnected', isConnected)
}

export const getChain = async () => {
  //@ts-ignore
  const chainId = await metamask.request({ method: 'eth_chainId' })
  network = parseInt(chainId, 16)
  return network
}

export const connected = () => {
  return isConnected
}

export const getAddress = async (refresh?: boolean) => {
  return await (await getSigner(refresh)).getAddress()
}

export const getContract = async function <T extends Contract>(
  name: string,
): Promise<T> {
  let contract = cache[name]

  if (!contract) {
    const signer = await getSigner()
    contract = new ethers.Contract(
      //@ts-ignore
      manifests[1].contracts[name].address,
      //@ts-ignore
      manifests[1].contracts[name].abi,
      signer,
    ) as T
    cache[name] = contract
  }

  return contract as T
}

const alchemy = new Alchemy({
  apiKey: 'L7Bd1aDjgKNWoKhONU6VPr_BQJweKOp5',
  network: Network.ETH_MAINNET,
})

export const getNFTData = async (address: string, token_id: string) => {
  if ((await getChain()) === 1338) {
    return {
      media: [{ thumbnail: 'http://localhost://' }],
    }
  }
  //special case for cryptopunks
  if (address === '0xcc21303aE97d2000ACEf1f414337fA3091490976') {
    return alchemy.nft.getNftMetadata(
      '0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb',
      token_id,
    )
  }
  return alchemy.nft.getNftMetadata(address, token_id)
}
