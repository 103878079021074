import { useMemo, useState } from 'react'

import { Select, ChakraStylesConfig } from 'chakra-react-select'

import color from '../styles/mixins/color'

const styles: ChakraStylesConfig = {
  option: (provided, state) => ({
    color: '#000',
    cursor: 'pointer',
    padding: 1,
    fontFamily: 'Changa',
    fontWeight: 'normal',
  }),
  valueContainer: (provided, state) => ({
    color: '#fff',
    cursor: 'pointer',
    fontFamily: 'Changa',
    fontWeight: 'normal',
    lineHeight: 10,
    paddingTop: 1,
    paddingBottom: 2,
    paddingLeft: 5,
    textAlign: 'left',
    backgroundColor: 'rgba(255, 255, 255, .05)',
  }),
}

const data = require('../assets/supported-contracts.json').contracts

interface DropDownProps {
  onChange: any
}

export const ContractDropDownList: React.FC<DropDownProps> = ({ onChange }) => {
  const [value, setValue] = useState(data[0])

  const handleChange = (item: any) => {
    setValue(item.value)
    onChange(item.value)
  }

  return (
    <Select
      chakraStyles={styles}
      onChange={handleChange}
      options={data}
      focusBorderColor={color('blue')}
      placeholder="Select your NFT collection"
    />
  )
}
