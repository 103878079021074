import { useEffect, useState } from 'react'

import { Spinner } from '@chakra-ui/react'
import styled from 'styled-components'

import Background from '../assets/custom_pxlbot_template_c1.png'
import OverlayImage from '../assets/overlay.png'
import SVG from './SVG'

const Container = styled.div`
  position: relative;
  width: auto;
  padding: 20px;
`
const ImageContainer = styled.div`
  position: relative;
  width: 30vw;
  height: 30vw;
  background: url(${Background}) no-repeat center center;
  background-size: cover;
  display: block;
`

const ImageTile = styled.div<{ ready?: boolean }>`
  position: absolute;
  top: 22.75%;
  left: 34.33%;
  width: 30.5%;
  height: 30.5%;
  display: ${(props) => (!props.ready ? 'none' : 'flex')};
`

const Overlay = styled.img`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
`

const NFTThumb = styled.img`
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  margin: 0;
`

const SpinContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

function CryoContainer(props: { url: any; svg: any; ready: boolean }) {
  const [isLoadingThumb, setIsLoadingThumb] = useState(false)

  const onImgLoaded = () => {
    setIsLoadingThumb(false)
  }

  useEffect(() => {
    if (props.svg) {
      setIsLoadingThumb(false)
    }
  }, [props.svg])

  useEffect(() => {
    if (props.url) {
      setIsLoadingThumb(true)
    }
  }, [props.url])

  useEffect(() => {
    setIsLoadingThumb(props.ready)
  }, [props.ready])

  return (
    <Container>
      <ImageContainer>
        <ImageTile ready={props.ready}>
          {props.url ? (
            <NFTThumb src={props.url} onLoad={onImgLoaded} />
          ) : (
            <SVG disabled={!props.svg} svg={props.svg} />
          )}
          {isLoadingThumb ? (
            <SpinContainer>
              <Spinner />
            </SpinContainer>
          ) : null}
        </ImageTile>
        <Overlay src={OverlayImage} />
      </ImageContainer>
    </Container>
  )
}

export default CryoContainer
