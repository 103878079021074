import { createGlobalStyle } from 'styled-components'

import BGImage from '../assets/nebula_dithered.png'
import WalletImageWhite from '../assets/wallet-white.svg'
import WalletImage from '../assets/wallet.svg'
import colors from './colors'
import color from './mixins/color'
import toRem from './mixins/toRem'

const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
    
    body, div, main, header, footer, sidebar, menu {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    html, body, button {
        font-family: Unica One, sans-serif;
    }
    
    html, body {
        min-height: 100vh;
        background-image: url(${BGImage}) !important;
        color: ${color('white')};
        font-size: ${toRem(18)};
        line-height: ${toRem(18)};
    }
    
    .row {
        flex-direction: row;
    }

    h1 {
        text-transform: uppercase;
        font-weight: 500;
        font-size: ${toRem(72)};
        line-height: ${toRem(72)};
        margin-bottom: ${toRem(30)};
        margin-top: ${toRem(-10)};
    }
    
    h2 {
        font-weight: normal;
        text-transform: uppercase;
        font-size: ${toRem(36)};
        line-height: ${toRem(60)};
        margin-bottom: ${toRem(20)};
        margin-top: ${toRem(-18)};
    }
    
    h3 {
        text-transform: uppercase;
        font-weight: normal;
        font-size: ${toRem(18)};
        line-height: ${toRem(18)};
        margin: 0;
        padding: 0;
        color: ${color('green')};
        margin-bottom: ${toRem(15)};
    }

    .button, button {
        min-width: ${toRem(260)};
        height: ${toRem(57)};
        border-radius: 0;
        background-color: transparent;
        border: 3px solid ${color('blue')};
        color: ${color('white')};

        font-weight: 500;
        font-size: ${toRem(18)};
        line-height: ${toRem(18)};
        text-align: center;
        text-transform: uppercase;

        cursor: pointer;

        transition: all 0.3s;
        display: flex;
        align-items: center;
        justify-content: center;
        text-decoration: none;
        padding: 0 ${toRem(20)};
    }

    .button.mint, button.mint {
        border: 3px solid ${color('green')};
        font-size: ${toRem(24)};
        line-height: ${toRem(24)}; 
        height: ${toRem(74)};
    }

    .button:hover, button:hover {
        background-color: ${color('blue')};
    }
   
    .button.mint:hover, button.mint:hover {
        background-color: ${color('green')};
    }
  
    .button.disabled, button:disabled {
        background-color: transparent;
        color: #888888;
    }

    a {
        color: ${color('white')};
    }

    .wallet {
        padding-right: ${toRem(60)};
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    .wallet::after {
        content: '';
        display: block;
        width: ${toRem(17)};
        height: ${toRem(17)};
        background: url('${WalletImage}') no-repeat center center;
        position: absolute;
        right: ${toRem(20)};
    }
    
    .wallet:hover::after {
        background: url('${WalletImageWhite}') no-repeat center center;
        position: absolute;
        right: ${toRem(20)};
    }
    
    .wallet.disabled:hover::after {
        background: url('${WalletImage}') no-repeat center center;
        position: absolute;
        right: ${toRem(20)};
    }

    .wallet.disabled{
        background-color: transparent;
        color: #888888;
        cursor: default;
    }
    
    
    .mb {
        margin-bottom: ${toRem(30)};
    }
  
    .mt {
        margin-top: ${toRem(30)};
    }
    
    // Get a class for each color
    ${Object.keys(colors).map(
      (key) => `
    .${key} {
        color: ${color(key as keyof typeof colors)};
    }
    `,
    )}

`

export default GlobalStyles
