import styled from 'styled-components'

const ResponsiveImage = styled.img<{ maxWidth?: number }>`
  width: 35%;
  max-width: ${(props) => (props.maxWidth ? `${props.maxWidth}px` : '100%')};
  height: auto;
  margin-bottom: 35px;
`

export default ResponsiveImage
